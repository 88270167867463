<!--
 * @Author: 李波
 * @Date: 2019-11-10 17:23:12
 * @LastEditors: 王鹏
 * @LastEditTime: 2020-03-02 17:43:25
 -->

<template>
  <div class="container">
    <div class="top bgpr" :style="'background:'+getStorage('theme','')">
      <img class="tx_bj" src="@/assets/nbs/img/tx-bj.png">
      <img class="head_img"  src="@/assets/nbs/img/head_img.png" >
      <div class="top_text">【{{familyname}}】的家庭</div>
    </div>
    <div class="content">
      <div class="btn_center" v-if="btnshow">
        <!-- 编辑信息 -->
        <div :style="'background:'+getStorage('theme','')" class="btn_edit bgpr imgw" @click="compile(familyname)"><img src="@/assets/dr/editinfo.png">编辑信息</div>
        <!-- 更新报告 -->
        <div :style="'background:'+getStorage('theme','')" class="btn_update bgpr imgw" @click="updateReport()"><img src="@/assets/dr/refersh.png">更新报告</div>
      </div>
      <!-- 家庭资料 -->
      <div class="jt_content">
        <div class="jt_text">家庭资料</div>
      </div>
      <!-- 卡片展示成员信息 -->
      <div class="jt_card-center">
        <div class="jt_card" v-for="(item,index) in family" :key="index" :style="'background: url('+getStorage('ossurl','')+getStorage('imgs',{}).headbg+');background-size: contain;background-repeat: no-repeat'">
          <img class="ry_img" v-if="item.sex === '2'"  src="@/assets/nbs/img/head_img.png" >
          <img class="ry_img" v-else  src="@/assets/nbs/img/women_head.png" >
          <div class="jt_card-relation">
              <!-- {{ item.relationvalue.label }} -->
              {{ item.relation }}
          </div>
          <div class="jt_card-name">
              {{ item.name }}
          </div>
          <div class="jt_card-date">
              {{ item.birthdate }}
          </div>
        </div>
      </div>
      <!-- 应备费用 -->
      <div class="should_cos">
        <div class="should_cos-text">应备费用</div>
        <div>
          <div class="should_text-costliving should_text-public"> <span :style="'background:'+getStorage('theme','')" class="blod"></span>生活  |  住房费用</div>
          <!-- 生活费用 -->
          <div class="live">
            <div>
              <table class="table_live">
                <tr class="tr_background">
                  <th :style="'background:'+getStorage('theme','')">生活费</th>
                  <th :style="'background:'+getStorage('theme','')">教育规划</th>
                </tr>
                <tr>
                  <td>{{ SHFY.bvalue ? SHFY.bvalue: '0' }}元/月</td>
                  <td>{{ JHQX.bbegages? JHQX.bbegages: '0'  }}岁</td>
                </tr>
              </table>
            </div>
            <div>
              <table class="table_live">
                <tr class="tr_background">
                  <th :style="'background:'+getStorage('theme','')">房租费用</th>
                  <th :style="'background:'+getStorage('theme','')">预计租期</th>
                </tr>
                <tr>
                  <td>{{ house.fz ? house.fz: '0'  }}元/月</td>
                  <td>{{ house.fzyars ? house.fzyars : '0' }}年</td>
                </tr>
              </table>
            </div>
          </div>
          <!-- 住房商业贷款 -->
          <div class="table-width">
            <table>
              <tr class="tr_background">
                <th :style="'background:'+getStorage('theme','')" class="hous_width-first th_public" >商业贷款费用</th>
                <th :style="'background:'+getStorage('theme','')" class="hous_th-center th_public">贷款年限</th>
                <th :style="'background:'+getStorage('theme','')" class="hous_th-center th_public">已还年限</th>
                <th :style="'background:'+getStorage('theme','')" class="hous_th-last th_public">每月商业贷款还款金额</th>
              </tr>
              <tr>
                <td>{{ house.sdmount? house.sdmount : '0' }}元</td>
                <td>{{ house.sdyears?house.sdyears : '0' }}年</td>
                <td>{{house.yhsdyears? house.yhsdyears : '0'}}年</td>
                <td>{{house.sdmon?house.sdmon : '0'}}元</td>
              </tr>
            </table>
          </div>
          <!-- 住房公积金贷款 -->
          <div class="table-width">
            <table>
              <tr class="tr_background">
                <th :style="'background:'+getStorage('theme','')" class="hous_width-first th_public" >公积金贷款费用</th>
                <th :style="'background:'+getStorage('theme','')" class="hous_th-center th_public">贷款年限</th>
                <th :style="'background:'+getStorage('theme','')" class="hous_th-center th_public">已还年限</th>
                <th :style="'background:'+getStorage('theme','')" class="hous_th-last th_public">每月公积金贷款还款金额</th>
              </tr>
              <tr>
                <td>{{ house.gjjmount? house.gjjmount: '0' }}元</td>
                <td>{{ house.gjjyears? house.gjjyears: '0'  }}年</td>
                <td>{{ house.yhgjjyears?house.yhgjjyears: '0' }}年</td>
                <td>{{ house.gjjmon?house.gjjmon : '0'}}元</td>
              </tr>
            </table>
          </div>
          <!-- 父母效养金 -->
          <div class="parent_cost module-interv">
            <div class="should_text-public"><span :style="'background:'+getStorage('theme','')" class="blod"></span>父母赡养金</div>
            <div class="table_width-title">
              <table>
                <tr>
                  <th :style="'background:'+getStorage('theme','')" class="th_public parant_self">
                    <img :src="getStorage('ossurl','')+getStorage('imgs',{}).self">
                    本人支出
                  </th>
                  <th class="parant_self-value th_public-value">{{ FMZY.bvalue?FMZY.bvalue : '0' }}元/月</th>
                  <th :style="'background:'+getStorage('theme','')" class="th_public parant_spouse">
                    <img :src="getStorage('ossurl','')+getStorage('imgs',{}).spouse">
                    配偶支出
                  </th>
                  <th class="parant_spouse-value th_public-value">{{ FMZY.pvalue?FMZY.pvalue:'0' }}元/月</th>
                </tr>
              </table>
            </div>
          </div>
          <!-- 教育费用 -->
          <div class="module-interv">
            <div class="should_text-public"><span :style="'background:'+getStorage('theme','')" class="blod"></span>教育费用</div>
            <div class="table_width-title">
              <div class="scroll_parent" :style="styles">
              <div class="education_roll">
              <table id="table">
                <tr class="tr_background tr_style">
                  <th :style="'background:'+getStorage('theme','')" class="th_public children th_style">子女姓名</th>
                  <th :style="'background:'+getStorage('theme','')" class="th_public education" v-if="znshow">子女姓名</th>
                  <th :style="'background:'+getStorage('theme','')" class="th_public education">幼儿园</th>
                  <th :style="'background:'+getStorage('theme','')" class="th_public education">小学</th>
                  <th :style="'background:'+getStorage('theme','')" class="th_public education">初中</th>
                  <th :style="'background:'+getStorage('theme','')" class="th_public education">高中</th>
                  <th :style="'background:'+getStorage('theme','')" class="th_public education">大学</th>
                  <th :style="'background:'+getStorage('theme','')" class="th_public education">研究生</th>
                  <th :style="'background:'+getStorage('theme','')" class="th_public education">博士</th>
                </tr>
                <tr v-for="(item,index) in education" :key="index" class="tr_style">
                    <td class="td_style">{{ item.name }}</td>
                  <td class="education td_bordeer">{{ item.playschool  }}万元</td>
                  <td class="education" v-if="znshow">{{ item.playschool  }}万元</td>
                  <td class="education">{{ item.primaryschool  }}万元</td>
                  <td class="education">{{ item.midschool  }}万元</td>
                  <td class="education">{{ item.highschool  }}万元</td>
                  <td class="education">{{ item.university }}万元</td>
                  <td class="education">{{ item.graduate  }}万元</td>
                  <td class="education">{{ item.doctor }}万元</td>
                </tr>
              </table>
              </div>
            </div>
            </div>
          </div>
          <!-- 其他贷款 -->
          <div class="module-interv">
            <div class="should_text-public"><span :style="'background:'+getStorage('theme','')" class="blod"></span>其他贷款</div>
            <div class="table_width-title">
              <table>
                <tr class="tr_background">
                  <th :style="'background:'+getStorage('theme','')" class="th_public dkother">贷款项目</th>
                  <th :style="'background:'+getStorage('theme','')" class="th_public dkother">贷款金额</th>
                  <th :style="'background:'+getStorage('theme','')" class="th_public dkother">贷款年限</th>
                  <th :style="'background:'+getStorage('theme','')" class="th_public dkother">已还年限</th>
                  <th :style="'background:'+getStorage('theme','')" class="th_public dkother">月偿还金额</th>
                </tr>
                <tr v-for="(item, index) in otherdk" :key="index">
                  <td>{{ item.xnname?item.xnname: '' }}</td>
                  <td>{{ item.bdkmount?item.bdkmount : '0' }}元</td>
                  <td>{{ item.bdkyears ?item.bdkyears : '0' }}年</td>
                  <td>{{ item.byhyears ?item.byhyears : '0' }}年</td>
                  <td>{{ item.bmon?item.bmon : '0' }}元</td>
                </tr>
              </table>
            </div>
          </div>
          <!-- 医疗费用 -->
          <div class="module-interv">
            <div class="should_text-public"><span :style="'background:'+getStorage('theme','')" class="blod"></span>医疗费用</div>
            <div class="table_width-title">
              <table>
                <tr class="tr_background">
                  <th :style="'background:'+getStorage('theme','')" class="th_public costother">病房等级</th>
                  <th :style="'background:'+getStorage('theme','')" class="th_public costother">护理费</th>
                  <th :style="'background:'+getStorage('theme','')" class="th_public costother">收入损失</th>
                  <th :style="'background:'+getStorage('theme','')" class="th_public costother">医疗费</th>
                </tr>
                <tr>
                  <td>{{ bfdj.bvalue?bfdj.bvalue : '' }}</td>
                  <td>{{ hlfy.bvalue?hlfy.bvalue : '0' }}元</td>
                  <td>{{ srss.bvalue?srss.bvalue : '0' }}元</td>
                  <td>{{ ylfy.bvalue?ylfy.bvalue : '0' }}元</td>
                </tr>
              </table>
            </div>
          </div>
          <!-- 其他费用 -->
          <div class="module-interv">
            <div class="should_text-public"><span :style="'background:'+getStorage('theme','')" class="blod"></span>其他费用</div>
            <div class="table_width-title">
              <table>
                <tr class="tr_background">
                  <th :style="'background:'+getStorage('theme','')" class="th_public costother">关系</th>
                  <th :style="'background:'+getStorage('theme','')" class="th_public costother">每年费用金额</th>
                  <th :style="'background:'+getStorage('theme','')" class="th_public costother">起始年龄</th>
                  <th :style="'background:'+getStorage('theme','')" class="th_public costother">结束年龄</th>
                </tr>
                <tr>
                  <td>本人</td>
                  <td>{{ QTFY.bvalue?QTFY.bvalue: '0' }}元</td>
                  <td>{{ QTFY.bbegages?QTFY.bbegages: '0' }}岁</td>
                  <td>{{ QTFY.bendages?QTFY.bendages: '0' }}岁</td>
                </tr>
                <tr>
                  <td>配偶</td>
                  <td>{{ QTFY.pvalue?QTFY.pvalue: '0' }}元</td>
                  <td>{{ QTFY.pbegages?QTFY.pbegages : '0' }}岁</td>
                  <td>{{ QTFY.pendages?QTFY.pendages: '0' }}岁</td>
                </tr>
              </table>
            </div>
          </div>
          <!-- 最后费用 -->
          <div class="module-interv">
            <div class="should_text-public"><span :style="'background:'+getStorage('theme','')" class="blod"></span>最后费用</div>
            <div class="table_width-title">
              <table>
                <tr>
                  <th :style="'background:'+getStorage('theme','')" class="th_public end_cost">费用金额</th>
                  <th class="end_cost-value th_public-value">{{ ZHFY.bvalue?ZHFY.bvalue: '0' }}元</th>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
      <!-- 已备费用 -->
      <div class="already_cos">
        <div class="already_cos-text">已备费用</div>
        <!-- 家庭收入 -->
        <div>
            <div class="should_text-public"><span :style="'background:'+getStorage('theme','')" class="blod"></span>家庭年收入</div>
            <div class="table_width-title">
              <table>
                <tr class="tr_background">
                  <th :style="'background:'+getStorage('theme','')" class="th_public jt_income">本人年收入</th>
                  <th :style="'background:'+getStorage('theme','')" class="th_public jt_income">结束年龄</th>
                  <th :style="'background:'+getStorage('theme','')" class="th_public jt_income">配偶年收入</th>
                  <th :style="'background:'+getStorage('theme','')" class="th_public jt_income">结束年龄</th>
                </tr>
                <tr>
                  <td>{{ familycost.bvalue ? familycost.bvalue : '0' }}元</td>
                  <td>{{ familycost.bendages ? familycost.bendages : '0'}}岁</td>
                  <td>{{ familycost.pvalue ? familycost.pvalue : '0' }}元</td>
                  <td>{{ familycost.pendages ? familycost.pendages : '0' }}岁</td>
                </tr>
              </table>
            </div>
          </div>
        <!-- 其他收入 -->
        <div class="module-interv">
          <div class="should_text-public"><span class="blod" :style="'background:'+getStorage('theme','')"></span>其他收入</div>
          <div class="table_width-title">
            <table>
              <tr class="tr_background">
                <th :style="'background:'+getStorage('theme','')" class="th_public costother">关系</th>
                <th :style="'background:'+getStorage('theme','')" class="th_public costother">月收入金额</th>
                <th :style="'background:'+getStorage('theme','')" class="th_public costother">起始年龄</th>
                <th :style="'background:'+getStorage('theme','')" class="th_public costother">结束年龄</th>
              </tr>
              <tr>
                <td>本人</td>
                <td>{{ othercost.bvalue ? othercost.bvalue : '0' }}元</td>
                <td>{{ othercost.bbegages ? othercost.bbegages : '0' }}岁</td>
                <td>{{ othercost.bendages ? othercost.bendages : '0' }}岁</td>
              </tr>
              <tr>
                <td>配偶</td>
                <td>{{ othercost.pvalue ? othercost.pvalue : '0' }}元</td>
                <td>{{ othercost.pbegages ? othercost.pbegages : '0' }}岁</td>
                <td>{{ othercost.pendages ? othercost.pendages : '0' }}岁</td>
              </tr>
            </table>
          </div>
        </div>
        <!-- 资产 -->
        <div class="module-interv">
          <div class="should_text-public"><span class="blod" :style="'background:'+getStorage('theme','')"></span>家庭资产</div>
          <div class="table_width-title">
            <div class="scroll_parent education_scr" >
            <div class="family_roll">
            <table>
              <tr class="tr_background tr_style">
                <th :style="'background:'+getStorage('theme','')" class="th_public th_style">关系</th>
                <th :style="'background:'+getStorage('theme','')" class="th_public property" v-if="znshow">储蓄</th>
                <th :style="'background:'+getStorage('theme','')" class="th_public property">储蓄</th>
                <th :style="'background:'+getStorage('theme','')" class="th_public property">房产</th>
                <th :style="'background:'+getStorage('theme','')" class="th_public property">理财</th>
                <th :style="'background:'+getStorage('theme','')" class="th_public property">股票</th>
                <th :style="'background:'+getStorage('theme','')" class="th_public property">基金</th>
                <th :style="'background:'+getStorage('theme','')" class="th_public property">其他</th>
              </tr>
              <tr class="tr_style">
                <td class="td_style" >本人</td>
                <td class="property" v-if="znshow">{{ cx.bvalue ? cx.bvalue :' 0'}}元</td>
                <td class="property">{{ cx.bvalue ? cx.bvalue :' 0'}}元</td>
                <td class="property">{{ fc.bvalue ? fc.bvalue : '0'}}元</td>
                <td class="property">{{ lc.bvalue ? lc.bvalue : '0'}}元</td>
                <td class="property">{{ gp.bvalue ? gp.bvalue : '0'}}元</td>
                <td class="property">{{ jj.bvalue ? jj.bvalue : '0'}}元</td>
                <td class="property">{{ qt.bvalue ? qt.bvalue : '0'}}元</td>
              </tr>
              <tr class="tr_style">
                <td class="td_style">配偶</td>
                <td class="property" v-if="znshow">{{ cx.pvalue ? cx.pvalue : '0'}}元</td>
                <td class="property">{{ cx.pvalue ? cx.pvalue : '0'}}元</td>
                <td class="property">{{ fc.pvalue ? fc.pvalue : '0'}}元</td>
                <td class="property">{{ lc.pvalue ? lc.pvalue : '0'}}元</td>
                <td class="property">{{ gp.pvalue ? gp.pvalue : '0'}}元</td>
                <td class="property">{{ jj.pvalue ? jj.pvalue : '0'}}元</td>
                <td class="property">{{ qt.pvalue ? qt.pvalue : '0'}}元</td>
              </tr>
            </table>
            </div>
            </div>
          </div>
        </div>
        <!-- 其他给付 -->
        <div class="module-interv">
          <div class="should_text-public"><span class="blod" :style="'background:'+getStorage('theme','')"></span>其他给付</div>
          <div class="table_width-title">
            <table>
              <tr>
                <th :style="'background:'+getStorage('theme','')+';'+'border:0.5px solid'+getStorage('theme','')" class="th_public other-public">遗嘱津贴</th>
                <th class="other_value-public th_public-value">{{ yzjt.bvalue && yzjt.pvalue ? yzjt.bvalue + yzjt.pvalue : yzjt.bvalue ? yzjt.bvalue : yzjt.pvalue ? yzjt.pvalue:'0'  }}元</th>
                <th :style="'background:'+getStorage('theme','')+';'+'border:0.5px solid'+getStorage('theme','')" class="th_public other-public">公司抚恤金</th>
                <th class="other_value-public th_public-value">{{ fxj.bvalue && fxj.pvalue ? fxj.bvalue + fxj.pvalue : fxj.bvalue ? fxj.bvalue : fxj.pvalue ? fxj.pvalue : '0' }}元</th>
              </tr>
            </table>
          </div>
        </div>
        <!-- 退休后费用 -->
        <div class="module-interv">
          <div class="should_text-public"><span class="blod" :style="'background:'+getStorage('theme','')"></span>退休后费用</div>
          <div class="table_width-title">
            <table>
              <tr class="tr_background">
                <th :style="'background:'+getStorage('theme','')+';'+'border:0.5px solid'+getStorage('theme','')" class="th_public pension">关系</th>
                <th :style="'background:'+getStorage('theme','')+';'+'border:0.5px solid'+getStorage('theme','')" class="th_public pension">月退休金</th>
                <th :style="'background:'+getStorage('theme','')+';'+'border:0.5px solid'+getStorage('theme','')" class="th_public pension">起始年龄</th>
                <th :style="'background:'+getStorage('theme','')+';'+'border:0.5px solid'+getStorage('theme','')" class="th_public pension">结束年龄</th>
              </tr>
              <tr>
                <td>本人</td>
                <td>{{ TXFY.bvalue ? TXFY.bvalue: '0' }}元</td>
                <td>{{ TXFY.bbegages ? TXFY.bbegages: '0' }}岁</td>
                <td>{{ TXFY.bendages ?TXFY.bendages: '0' }}岁</td>
              </tr>
              <tr>
                <td>配偶</td>
                <td>{{ TXFY.pvalue ? TXFY.pvalue: '0' }}元</td>
                <td>{{ TXFY.pbegages ? TXFY.pbegages: '0' }}岁</td>
                <td>{{ TXFY.pendages ?TXFY.pendages: '0' }}岁</td>
              </tr>
            </table>
          </div>
        </div>
        <!-- 社保费用 -->
        <div class="module-interv">
          <div class="should_text-public"><span :style="'background:'+getStorage('theme','')" class="blod"></span>社保费用</div>
          <div class="table_width-title">
            <table>
              <tr class="tr_background">
                <th :style="'background:'+getStorage('theme','')" class="th_public social">关系</th>
                <th :style="'background:'+getStorage('theme','')" class="th_public social">社会养老</th>
                <th :style="'background:'+getStorage('theme','')" class="th_public social">商业养老</th>
                <th :style="'background:'+getStorage('theme','')" class="th_public social">公司养老</th>
              </tr>
              <tr>
                <td>本人</td>
                <td>{{ shyl.bvalue ? shyl.bvalue : '0' }}元</td>
                <td>{{ syyl.bvalue ? syyl.bvalue : '0' }}元</td>
                <td>{{ gstxj.bvalue ? gstxj.bvalue : '0' }}元</td>
              </tr>
              <tr>
                <td>配偶</td>
                <td>{{ shyl.pvalue ? shyl.pvalue : '0' }}元</td>
                <td>{{ syyl.pvalue ? syyl.pvalue : '0' }}元</td>
                <td>{{ gstxj.pvalue ? gstxj.pvalue : '0' }}元</td>
              </tr>
            </table>
          </div>
        </div>
        <!-- 个人保障 -->
        <div class="module-interv">
          <div class="should_text-public"><span :style="'background:'+getStorage('theme','')" class="blod"></span>个人保障</div>
          <div class="wrapper">
            <div class="caption">
              <span class="leftdiv context iconfont iconfenxi" :style="'color:'+getStorage('theme','') +'!important'"></span>
              <span class="leftword context" :style="'color:'+getStorage('theme','')+'!important'">保单保障分析</span>
              <span class="rightword">单位：万元</span>
            </div>
            <div class="rows">
              <div class="lf">
                <table class="left-table"
                       border="1"
                       :style="'background:'+getStorage('theme','')"
                       align="center">
                  <tr></tr>
                  <tr>重疾</tr>
                  <tr>意外</tr>
                  <tr>寿险</tr>
                  <tr>医疗</tr>
                  <tr>养老</tr>
                  <tr>教育</tr>
                  <tr>理财</tr>
                  <tr>姓名</tr>
                </table>
              </div>
              <div class="swp" v-if="pageData.length !== 0">
                <swiper :options="swiperOption"
                        class="swiper-wrap"
                        v-if="pageData.length !== 0"
                        ref="mySwiper">
                  <swiper-slide v-for="(item,index) of pageData"
                                :key="index">
                    <table cellspacing="0"
                           cellpadding="0"
                           class="b-table analyze">
                      <tr>
                        <th>参考</th>
                        <th>现有</th>
                      </tr>
                      <tr v-for="(it,i) of item.forehead"
                          :key="i">
                        <td>{{it.reference}}</td>
                        <td :style="'color:'+getStorage('theme','')">{{it.number}}</td>
                      </tr>
                      <tr class="lastname">
                        <td colspan="2">{{item.pname}}</td>
                      </tr>
                    </table>
                  </swiper-slide>
                  <div class="swiper-button-prev swiper-button-black"
                       v-show="showSign>1"
                       slot="button-prev"></div>
                  <div class="swiper-button-next swiper-button-black"
                       v-show="showSign>1"
                       slot="button-next"></div>
                  <div class="swiper-pagination tag_dot"
                       slot="pagination"></div>
                </swiper>
                <div class="swiper-scrollbar" ></div>
              </div>
              <div class="swp noanydata" v-if="pageData.length === 0">
                <p  class="nodata">暂无数据</p>
              </div>
            </div>
          </div>
        </div>
        </div>
         <!-- 家庭对应产品信息 -->
    <div v-if="isuser||ensurebloo">
      <div class="should_text-public"><span :style="'background:'+getStorage('theme','')" class="blod"></span>保障方案</div>
      <!-- <div class="caption">
        <span class="leftdiv context iconfont iconbaoxianchanpin"></span>
        <span class="leftword context">保障方案</span>
      </div> -->
      <div class="productwrapper">
        <div v-for="(item,index) of this.details" :key="index" >
        <div class="product" v-if="isuser||item.jhsno" :style="'border:1px solid '+getStorage('theme','')">
          <div class="producttop">
          <p class="producttopname" :style="'background:'+getStorage('theme','')">{{item.panme}}的保障方案推荐</p>
        </div>
          <div v-for="(cpdata,index) of item.cplist" :key="index" :class="item.hcon?'productcentop':''">
            <div class="productcen" v-if="cpdata.checked">
              <div class="productcen-lf">
                <img :src="msossurl + cpdata.iconhttp" alt="">
              </div>
              <div class="productcen-rg">
                <div class="productcen-rg-cen">
                  <div class="productcen-rg-top">
                    <p>{{cpdata.cpname}}</p>
                  </div>
                  <div class="productcen-rg-bom">
                    <p>首年保费：{{cpdata.mount}}元</p>
                    <p class="productcenp">保额：{{cpdata.amount}}</p>
                  </div>
                </div>
              </div>
            </div>
        <!-- <div class="productcen" @click="particulars"> -->
          </div>
          <div class="premium">
            <p>首年总保费：<span :style="'color:'+getStorage('theme','')">{{item.premiumnum}}元</span></p>
          </div>
        <div class="productbom">
          <div :class="isuser?'productbomlf':'productbomlff'">
            <md-button size="large"
                   type="primary"
                   :style="'background:'+getStorage('theme','')"
                   @click="makeout(item)">{{isuser?'预览保障方案':'查看保障方案'}}</md-button>
          </div>
          <div class="productbomrg" v-if="isuser">
            <md-button size="large"
                   type="primary"
                   :style="'background:'+getStorage('theme','')"
                   @click="cpdataedit(item,'nbs')" >编辑</md-button>
          </div>
        </div>
      </div>
        </div>
      </div>
    </div>
      <!-- 个别年度应备费用 -->
      <div class="individual_annual">
        <div class="already_cos-text">个别年度应备费用</div>
        <!-- 费用报表 -->
        <div>
          <ve-bar :data="chartData" :data-empty="dataempty" :settings="chartSettings" :colors="colors"  :grid="grid" :events="chartEvents" :extend="chartextend" width="356.25px" height="400px"></ve-bar>
        </div>
        <div class="individual">
          <div class="data_title">
            <div>{{ selfname }}</div>
            <div>{{ gbnd.bage }}岁</div>
            <div>{{ spousename }}</div>
            <div>{{ gbnd.page }}岁</div>
          </div>
          <div class="data_value">
            <div class="data_value-left">
              <img class="data_img" :src="getStorage('ossurl','')+getStorage('imgs',{}).shfy">
              <div class="value" :style="'color:'+getStorage('theme','')">{{ gbnd.livcost?gbnd.livcost:0 }}</div>
              <div class="label">生活费用</div>
            </div>
            <div class="data_value-right">
              <img class="data_img" :src="getStorage('ossurl','')+getStorage('imgs',{}).gjjdk">
              <div class="value" :style="'color:'+getStorage('theme','')">{{ gbnd.gjjloans?gbnd.gjjloans:0 }}</div>
              <div class="label">公积金贷款</div>
            </div>
            <div class="data_value-left">
              <img class="data_img" :src="getStorage('ossurl','')+getStorage('imgs',{}).sydk">
              <div class="value" :style="'color:'+getStorage('theme','')">{{ gbnd.busineloans?gbnd.busineloans:0 }}</div>
              <div class="label">商业贷款</div>
            </div>
            <div class="data_value-right">
              <img class="data_img" :src="getStorage('ossurl','')+getStorage('imgs',{}).fzfy">
              <div class="value" :style="'color:'+getStorage('theme','')">{{ gbnd.zhousecost?gbnd.zhousecost:0 }}</div>
              <div class="label">租房费用</div>
            </div>
            <div class="data_value-left">
              <img class="data_img" :src="getStorage('ossurl','')+getStorage('imgs',{}).jyfy">
              <div class="value" :style="'color:'+getStorage('theme','')">{{ gbnd.educost?gbnd.educost:0 }}</div>
              <div class="label">教育费用</div>
            </div>
            <div class="data_value-right">
              <img class="data_img" :src="getStorage('ossurl','')+getStorage('imgs',{}).fmxyj">
              <div class="value" :style="'color:'+getStorage('theme','')">{{ gbnd.xyjcost?gbnd.xyjcost:0 }}</div>
              <div class="label">孝养金费用</div>
            </div>
            <div class="data_value-left">
              <img class="data_img" :src="getStorage('ossurl','')+getStorage('imgs',{}).qtdk">
              <div class="value" :style="'color:'+getStorage('theme','')">{{ gbnd.otherloans?gbnd.otherloans:0 }}</div>
              <div class="label">其他贷款</div>
            </div>
            <div class="data_value-right">
              <img class="data_img" :src="getStorage('ossurl','')+getStorage('imgs',{}).qtfy">
              <div class="value" :style="'color:'+getStorage('theme','')">{{ gbnd.othercost?gbnd.othercost:0 }}</div>
              <div class="label">其他费用</div>
            </div>
          </div>
        </div>
      </div>
      <!-- 应备费用累计 -->
      <div>
        <div class="already_cos-text">应备费用累计</div>
        <!-- 应备费用报表 -->
        <div>
          <ve-bar :data="ybData" :settings="ybSettings" :data-empty="dataempty2" :colors="colors"  :grid="gridv" :events="ybEvents" :extend="ybextend" width="356.25px" height="400px"></ve-bar>
        </div>
        <div class="individual">
          <div class="data_title">
            <div>{{ selfname }}</div>
            <div>{{ yblist.bage }}岁</div>
            <div>{{ spousename }}</div>
            <div>{{ yblist.page }}岁</div>
          </div>
          <div class="data_value">
            <div class="data_value-left">
              <img class="data_img" :src="getStorage('ossurl','')+getStorage('imgs',{}).shfy">
              <div class="value" :style="'color:'+getStorage('theme','')">{{ yblist.livcost?yblist.livcost:0 }}</div>
              <div class="label">生活费用</div>
            </div>
            <div class="data_value-right">
              <img class="data_img" :src="getStorage('ossurl','')+getStorage('imgs',{}).gjjdk">
              <div class="value" :style="'color:'+getStorage('theme','')">{{ yblist.gjjloans?yblist.gjjloans:0 }}</div>
              <div class="label">公积金贷款</div>
            </div>
            <div class="data_value-left">
              <img class="data_img" :src="getStorage('ossurl','')+getStorage('imgs',{}).sydk">
              <div class="value" :style="'color:'+getStorage('theme','')">{{ yblist.busineloans?yblist.busineloans:0 }}</div>
              <div class="label">商业贷款</div>
            </div>
            <div class="data_value-right">
              <img class="data_img" :src="getStorage('ossurl','')+getStorage('imgs',{}).fzfy">
              <div class="value" :style="'color:'+getStorage('theme','')">{{ yblist.zhousecost?yblist.zhousecost:0 }}</div>
              <div class="label">租房费用</div>
            </div>
            <div class="data_value-left">
              <img class="data_img" :src="getStorage('ossurl','')+getStorage('imgs',{}).jyfy">
              <div class="value" :style="'color:'+getStorage('theme','')">{{ yblist.educost?yblist.educost:0 }}</div>
              <div class="label">教育费用</div>
            </div>
            <div class="data_value-right">
              <img class="data_img" :src="getStorage('ossurl','')+getStorage('imgs',{}).fmxyj">
              <div class="value" :style="'color:'+getStorage('theme','')">{{ yblist.xyjcost?yblist.xyjcost:0 }}</div>
              <div class="label">孝养金费用</div>
            </div>
            <div class="data_value-left">
              <img class="data_img" :src="getStorage('ossurl','')+getStorage('imgs',{}).qtdk">
              <div class="value" :style="'color:'+getStorage('theme','')">{{ yblist.otherloans?yblist.otherloans:0 }}</div>
              <div class="label">其他贷款</div>
            </div>
            <div class="data_value-right">
              <img class="data_img" :src="getStorage('ossurl','')+getStorage('imgs',{}).qtfy">
              <div class="value" :style="'color:'+getStorage('theme','')">{{ yblist.othercost?yblist.othercost:0 }}</div>
              <div class="label">其他费用</div>
            </div>
            <div class="data_value-left">
              <img class="data_img" :src="getStorage('ossurl','')+getStorage('imgs',{}).ylfy">
              <div class="value" :style="'color:'+getStorage('theme','')">{{ yblist.ylfy?yblist.ylfy:0 }}</div>
              <div class="label">医疗费用</div>
            </div>
            <div class="data_value-right">
              <img class="data_img" :src="getStorage('ossurl','')+getStorage('imgs',{}).zhfy">
              <div class="value" :style="'color:'+getStorage('theme','')">{{ yblist.zhfy?yblist.zhfy:0 }}</div>
              <div class="label">最后费用</div>
            </div>
          </div>
        </div>
      </div>
      <!-- 已备费用累计 -->
      <div>
        <div class="already_cos-text">已备费用累计</div>
        <!-- 已备费用报表 -->
        <div>
          <ve-bar :data="shouldData" :settings="shouldSettings" :data-empty="dataempty3" :colors="colors"  :grid="gridva" :events="shouldEvents" :extend="shouldextend" width="356.25px" height="400px"></ve-bar>
        </div>
        <div class="individual">
          <div class="data_title">
            <div>{{ selfname }}</div>
            <div>{{ shoukdlist.bage }}岁</div>
            <div>{{ spousename }}</div>
            <div>{{ shoukdlist.page }}岁</div>
          </div>
          <div class="data_value">
            <div class="data_value-left">
              <img class="data_img" :src="getStorage('ossurl','')+getStorage('imgs',{}).shfy">
              <div class="value" :style="'color:'+getStorage('theme','')">{{ shoukdlist.pfutureIncome?shoukdlist.pfutureIncome:0 }}</div>
              <div class="label">年收入</div>
            </div>
            <div class="data_value-right">
              <img class="data_img" :src="getStorage('ossurl','')+getStorage('imgs',{}).jtzc">
              <div class="value" :style="'color:'+getStorage('theme','')">{{ shoukdlist.familyAssets?shoukdlist.familyAssets:0 }}</div>
              <div class="label">家庭资产</div>
            </div>
            <div class="data_value-left">
              <img class="data_img" :src="getStorage('ossurl','')+getStorage('imgs',{}).yzjt">
              <div class="value" :style="'color:'+getStorage('theme','')">{{ shoukdlist.comPension?shoukdlist.comPension:0 }}</div>
              <div class="label">遗嘱津贴</div>
            </div>
            <div class="data_value-right">
              <img class="data_img" :src="getStorage('ossurl','')+getStorage('imgs',{}).fxj">
              <div class="value" :style="'color:'+getStorage('theme','')">{{ shoukdlist.willWance?shoukdlist.willWance:0 }}</div>
              <div class="label">公司抚恤金</div>
            </div>
            <div class="data_value-left">
              <img class="data_img" :src="getStorage('ossurl','')+getStorage('imgs',{}).qtgf">
              <div class="value" :style="'color:'+getStorage('theme','')">{{ shoukdlist.otherIncome?shoukdlist.otherIncome:0 }}</div>
              <div class="label">其他收入</div>
            </div>
            <div class="data_value-right">
              <img class="data_img" :src="getStorage('ossurl','')+getStorage('imgs',{}).fmxyj">
              <div class="value" :style="'color:'+getStorage('theme','')">{{ shoukdlist.txfy?shoukdlist.txfy:0 }}</div>
              <div class="label">退休后费用</div>
            </div>
            <div class="data_value-left">
              <img class="data_img" :src="getStorage('ossurl','')+getStorage('imgs',{}).sbfy">
              <div class="value" :style="'color:'+getStorage('theme','')">{{ shoukdlist.sbty?shoukdlist.sbty:0 }}</div>
              <div class="label">社保费用</div>
            </div>
            <div class="data_value-right">
              <img class="data_img" :src="getStorage('ossurl','')+getStorage('imgs',{}).shfy">
              <div class="value" :style="'color:'+getStorage('theme','')">{{ shoukdlist.pyfy?shoukdlist.pyfy:0 }}</div>
              <div class="label">个人保障</div>
            </div>
          </div>
        </div>
      </div>
      <!-- 年度差额报表 -->
      <div>
        <div class="already_cos-text">年度差额</div>
        <div>
          <ve-histogram :data="balanceData" :data-empty="dataempty4" :settings="balanceSettings" width="356.25px" height="400px"></ve-histogram>
        </div>
        <div class=" annual_difference">
          <div class="balance_radius">
            <table>
              <tr class="tr_background" :style="'background:'+getStorage('theme','')">
                <th class="th_public balan">{{ selfname }}</th>
                <th class="th_public balan">{{ spousename }}</th>
                <th class="th_public balance">应备费用</th>
                <th class="th_public balance">已备费用</th>
                <th class="th_public balance">不足费用</th>
              </tr>
              <tr v-for="(item,index) in balancelist" :key="index" >
                <td :style="'background:'+getStorage('theme','')" class="balance_age">{{ item.bage }}</td>
                <td :style="'background:'+getStorage('theme','')" class="balance_age">{{ item.page }}  </td>
                <td>{{ item.ybcost }}</td>
                <td>{{ item.obcost }}</td>
                <td>{{ item.lackcost }}</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
      <div v-if="jointlytrue">
      <md-popup v-model="jointlytrue">
        <div class="jointlymold">
          <jointly ref="jointly" :form="form" @jointlymode="jointlymode"></jointly>
        </div>
    </md-popup>
    </div>
    </div>
     <div class="navsetting" v-if="!noShow&&user.rytype!=='Y'&&fromwhere=='app'">
      <div class="backbtn" :style="'height:'+'33%'" @click="backtopage">
        <span class="iconfont iconback"></span>
      </div>
      <div class="backtohome" :style="'height:'+'33%'" @click="backtohomepage">
        <span class="iconfont iconhome"></span>
      </div>
      <div class="shareicon" :style="'height:'+'33%'" @click="toShare">
        <span class="iconfont iconiconfontfenxiang2"></span>
      </div>
    </div>
    <div class="navsetting2" v-if="user.rytype==='Y'&&!noShow">
       <div class="backbtn" :style="'height:'+'100%'" @click="backtopage">
        <span class="iconfont iconback"></span>
      </div>
    </div>
  </div>
</template>

<script>
import { prodlist } from '@/api/jhs/productlist'
import { Dialog, Toast } from 'mand-mobile'
import { reportShow } from '@/api/nbs/nbsinfo/index'
import { getStorage, AppShare, setStorage, stopScroll, canScroll } from '@/lib/util'
import config from '@/config'
import {
  DoRecord
} from '@/api/abt/customerOperation/common/index'
import initWebSocket from '@/mixins/websock'
import { wechatshare } from '@/lib/wechat_share'
import jointly from '../../../jhs/productcheck/component/jointlyrespon'

import 'v-charts/lib/style.css'
const { redirect_uri, cvu } = config
export default {
  mixins: [initWebSocket],
  components: {
    jointly,
    [Dialog.name]: Dialog
  },
  data () {
    this.colors = ['#c23531', '#2f4554', '#61a0a8',
      '#4672d4', '#91c7ae', '#749f83',
      '#ca8622', '#bda29a']
    var self = this
    this.chartEvents = {
      click: function (e) {
        self.gbindex = e.dataIndex
      }
    }
    this.chartextend = {
      'xAxis.0.axisLabel.rotate': 25
    }
    this.ybEvents = {
      click: function (e) {
        self.ybindex = e.dataIndex
      }
    }
    this.ybextend = {
      'xAxis.0.axisLabel.rotate': 25
    }
    this.shouldEvents = {
      click: function (e) {
        self.shouldindex = e.dataIndex
      }
    }
    this.shouldextend = {
      'xAxis.0.axisLabel.rotate': 25
    }
    this.chartSettings = {
      labelMap: {
        bage: '本人年龄',
        page: '配偶年龄',
        busineloans: '商业贷款费用',
        educost: '教育费用',
        gjjloans: '公积金贷款',
        livcost: '生活费用',
        othercost: '其他费用',
        otherloans: '其他贷款',
        xyjcost: '孝养金费用',
        zhousecost: '租房费用'
      },
      stack: {
        '费用': ['busineloans', 'educost', 'gjjloans', 'livcost', 'othercost', 'otherloans', 'xyjcost', 'zhousecost']
      }
    }
    this.ybSettings = {
      labelMap: {
        bage: '本人年龄',
        page: '配偶年龄',
        livcost: '生活费用',
        gjjloans: '公积金贷款',
        busineloans: '商业贷款费用',
        zhousecost: '租房费用',
        educost: '教育费用',
        xyjcost: '孝养金费用',
        otherloans: '其他贷款',
        othercost: '其他费用',
        ylfy: '医疗费用',
        zhfy: '最后费用'
      },
      stack: {
        '费用': ['livcost', 'gjjloans', 'busineloans', 'zhousecost', 'educost', 'xyjcost', 'otherloans', 'othercost', 'ylfy', 'zhfy']
      }
    }
    this.shouldSettings = {
      labelMap: {
        bage: '本人年龄',
        page: '配偶年龄',
        pfutureIncome: '年收入',
        otherIncome: '其他收入',
        familyAssets: '家庭资产',
        willWance: '遗嘱津贴',
        comPension: '公司抚恤金',
        txfy: '退休后费用',
        sbty: '社保费用',
        pyfy: '个人保障'
      },
      stack: {
        '费用': ['pfutureIncome', 'otherIncome', 'familyAssets', 'willWance', 'comPension', 'txfy', 'sbty', 'pyfy']
      }
    }
    this.balanceSettings = {
      labelMap: {
        bage: '本人年龄',
        page: '配偶年龄',
        ybcost: '应备费用',
        obcost: '已备费用',
        lackcost: '不足费用'
      },
      metrics: ['ybcost', 'obcost', 'lackcost']
    }
    return {
      /* 分享相关 */
      fromwhere: '',
      noShow: false,
      //
      dataempty: true,
      dataempty2: true,
      dataempty3: true,
      dataempty4: true,
      data: '',
      comid: this.$route.query.comid,
      empno: this.$route.query.empno,
      gbindex: '',
      ybindex: '',
      shouldindex: '',
      familyname: '',
      familyno: '',
      updatestatus: false,
      Gbieniandu: [],
      gbnd: {
        bage: '0',
        page: '0',
        livcost: '0',
        busineloans: '0',
        educost: '0',
        othercost: '0',
        otherloans: '0',
        zhousecost: '0',
        xyjcost: '0',
        gjjloans: '0'
      },
      yblist: {
        bage: '0',
        page: '0',
        livcost: '0',
        busineloans: '0',
        educost: '0',
        othercost: '0',
        otherloans: '0',
        zhousecost: '0',
        xyjcost: '0',
        gjjloans: '0',
        ylfy: '0',
        zhfy: '0'
      },
      shoukdlist: {
        bage: '0',
        page: '0',
        pfutureIncome: '0',
        otherIncome: '0',
        familyAssets: '0',
        willWance: '0',
        comPension: '0',
        txfy: '0',
        sbty: '0',
        pyfy: '0'
      },
      family: [
        // { relationvalue: {
        //   label: ''
        // }
        // }
      ],
      // 已备列表展示数据
      ybcost: {},
      JHQX: { bbegages: '' },
      bfdj: { bvalue: '' },
      hlfy: { bvalue: '' },
      srss: { bvalue: '' },
      ylfy: { bvalue: '' },
      FMZY: { bvalue: '', pvalue: '' },
      ZHFY: { bvalue: '' },
      SHFY: {},
      TXFY: { bvalue: '' },
      cx: {},
      fc: {},
      lc: {},
      jj: {},
      gp: {},
      qt: {},
      fxj: {},
      yzjt: {},
      shyl: {},
      syyl: {},
      gstxj: {},
      QTFY: {
        bvalue: '',
        pvalue: '',
        bbegages: '',
        bendages: '',
        pbegages: '',
        pendages: ''
      },
      // 应备列表展示数据
      obcost: {},
      familycost: {},
      othercost: {},
      house: {},
      education: [],
      otherdk: [],
      selfname: '暂无',
      spousename: '暂无',
      // 个别年度数据
      chartData: {
        columns: [ 'bage', 'livcost', 'busineloans', 'educost', 'othercost', 'otherloans', 'zhousecost', 'xyjcost', 'gjjloans' ],
        rows: []
      },
      // 应备累计数据
      ybData: {
        columns: [ 'bage', 'livcost', 'busineloans', 'educost', 'othercost', 'ylfy', 'otherloans', 'zhousecost', 'xyjcost', 'gjjloans', 'zhfy' ],
        rows: []
      },
      // 已备累计数据
      shouldData: {
        columns: [ 'bage', 'pfutureIncome', 'otherIncome', 'familyAssets', 'willWance', 'comPension', 'txfy', 'sbty', 'pyfy' ],
        rows: []
      },
      // 差额数据
      balanceData: {
        columns: [ 'bage', 'ybcost', 'obcost', 'lackcost' ],
        rows: []
      },
      balancelist: [],
      grid: {
        right: 25,
        top: 80
      },
      gridv: {
        top: 100,
        right: 25
      },
      gridva: {
        right: 25,
        top: 70
      },
      btnshow: true,
      znshow: true,
      height: '',
      styles: {},
      /* 行为追踪 */
      user: {},
      pageData: [],
      rytype: '',
      suserid: '',
      showSign: '',
      swiperOption: {
        slidesPerView: 1,
        autoplay: false,
        // eslint-disable-next-line no-dupe-keys
        slidesPerView: 'auto',
        // freeMode: true,
        pagination: {
          el: '.swiper-pagination',
          dynamicBullets: true
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
          hideOnClick: true
        }
      },
      proddatalist: [],
      details: [],
      isuser: false,
      userempno: '',
      ensure: 0,
      ensurebloo: false,
      form: [],
      jointlytrue: false,
      msossurl: ''
    }
  },
  watch: {
    jointlytrue (val) {
      if (val) {
        stopScroll()
      } else {
        canScroll()
      }
    },
    'gbindex' () {
      this.gbnd = this.Gbieniandu[this.gbindex]
      this.$forceUpdate()
    },
    'ybindex' () {
      this.yblist = this.ybData.rows[this.ybindex]
      this.$forceUpdate()
    },
    'shouldindex' () {
      this.shoukdlist = this.shouldData.rows[this.shouldindex]
      this.$forceUpdate()
    }
  },
  created () {
    this.msossurl = cvu
    this.fromwhere = this.$route.query.fromwhere ? this.$route.query.fromwhere : ''
    this.user = getStorage('u_s', {})
    this.userempno = this.user.empno ? this.user.empno : ''
    this.empno = this.$route.query.empno ? this.$route.query.empno : this.user.empno
    this.comid = this.$route.query.comid ? this.$route.query.comid : this.user.comid
    this.rytype = this.$route.query.rytype ? this.$route.query.rytype : this.user.rytype
    this.suserid = this.$route.query.suserid ? this.$route.query.suserid : this.user.userid
    this.familyname = this.$route.query.familyname
    this.familyno = this.$route.query.familyno
    this.getReport()
    if (!this.$route.query.type && this.fromwhere !== 'app') {
      this.behaviorRecord()
      this.wxshare()
    } else {
      this.znshow = false
    }
    if (this.$route.query.type || this.$route.query.sharetype) {
      this.noShow = true
      this.btnshow = false
    }
    if (this.$route.query.type) {
      setStorage('ossurl', this.$route.query.ossurl)
      setStorage('imgs', JSON.parse(this.$route.query.imgs))
    }
  },
  methods: {
    wxshare () {
      let fxstr = {
        suserid: this.suserid,
        empno: this.empno,
        rytype: this.rytype,
        otype: '2',
        btagcode: '15'
      }
      let info = getStorage('u_s', {})
      let shareurl = `${redirect_uri}/nbsReport?familyname=${this.familyname}&familyno=${this.familyno}&comid=${this.comid}&empno=${this.empno}&recordtype=15&suserid=${this.user.userid}&rytype=${this.rytype}&sharetype=00`
      wechatshare(
        `${this.familyname}家庭的风险分析报告`,
        `您的好友${info.nickname}邀请您查看家庭风险分析报告`,
        info.headimg,
        encodeURI(shareurl),
        redirect_uri,
        fxstr
      )
    },
    updateReport () {
      Toast.loading('更新中...', 1000)
      this.getReport(true)
    },
    getReport (type) {
      reportShow({
        familyno: this.familyno,
        comid: this.comid,
        empno: this.empno
      }).then(res => {
        let Data = res.data.data
        Data.family.map(item => {
          // 取出本人和配偶姓名
          if (item.relation === '00') {
            this.selfname = item.name
          }
          if (item.relation === '02') {
            this.spousename = item.name
          }
          item.relation = item.relationvalue.label
        })
        this.family = Data.family
        for (let item of this.family) {
          let data = {
            familyno: item.familyno,
            memid: item.id,
            pname: item.name
          }
          this.proddatalist.push(data)
        }
        let data = {
          comid: this.comid,
          empno: this.empno,
          userempno: this.userempno,
          list: this.proddatalist
        }
        prodlist(data).then(res => {
          this.isuser = res.data.data.isuser
          this.details = res.data.data.list
          this.ensure = 0
          for (let item in this.details) {
            let a = 0
            this.details[item].cplist.forEach(v => {
              if (v.checked) {
                a++
                this.ensure++
              }
            })
            if (a == 0) {
              this.details[item].hcon = true
            }
          }
          if (this.ensure != 0) {
            this.ensurebloo = true
          }
          for (let item in this.details) {
            let premiumnum = 0
            this.details[item].cplist.forEach(v => {
              if (v.checked) {
                premiumnum += parseFloat(v.mount)
              }
            })
            this.details[item].premiumnum = premiumnum
          }
        })
        if (Data.count.Gbieniandu !== null) {
          this.Gbieniandu = Data.count.Gbieniandu
          this.gbnd = this.Gbieniandu[0]
          this.dataempty = false
        }
        this.chartData.rows = this.Gbieniandu
        if (Data.count.YbFyleiji !== null) {
          this.ybData.rows = Data.count.YbFyleiji
          this.yblist = this.ybData.rows[0]
          this.dataempty2 = false
        }

        if (Data.count.ObFyleiji != null) {
          this.shouldData.rows = Data.count.ObFyleiji
          this.shoukdlist = this.shouldData.rows[0]
          this.dataempty3 = false
        }
        if (Data.count.diffCountGroup !== null) {
          this.balanceData.rows = Data.count.diffCountGroup
          this.balancelist = Data.count.diffCountGroup
          this.dataempty4 = false
        }
        if (Data.YBFY.policyinfo !== null) {
          this.pageData = Data.YBFY.policyinfo
          this.showSign = Data.YBFY.policyinfo.length
        }
        this.obcost = Data.YBFY.familyybfy.OB
        this.ybcost = Data.YBFY.familyybfy.YB
        // 生活费
        if (this.ybcost.SHFY.length !== 0) {
          this.SHFY = this.ybcost.SHFY[0]
        }
        // 最后费用
        if (this.ybcost.ZHFY.length !== 0) {
          this.ZHFY = this.ybcost.ZHFY[0]
        }
        // 父母赡养
        if (this.ybcost.FMZY.length !== 0) {
          this.FMZY = this.ybcost.FMZY[0]
        }
        // 其他费用
        if (this.ybcost.QTFY.length !== 0) {
          this.QTFY = this.ybcost.QTFY[0]
        }
        // 教育规划
        if (this.ybcost.JHQX.length !== 0) {
          this.JHQX = this.ybcost.JHQX[0]
        }
        // 退休费用
        if (this.obcost.TXFY.length !== 0) {
          this.TXFY = this.obcost.TXFY[0]
        }
        // 住房费用
        if (Data.YBFY.house !== null) {
          this.house = Data.YBFY.house
        }
        // 教育费用
        if (Data.YBFY.education !== null) {
          this.education = Data.YBFY.education
          this.height = 1 + (this.education.length * 1.15)
          this.styles = {
            height: `${this.height}rem`
          }
        }
        // 其他贷款
        this.otherdk = Data.YBFY.otherdk
        // 判断家庭收入中的年收入和其他收入
        this.obcost.JTSR.forEach(item => {
          if (item.sxmid === 'NSR') {
            this.familycost = item
          }
          if (item.sxmid === 'QTSR') {
            this.othercost = item
          }
        })
        // 判断家庭资产下子项目
        this.obcost.JTZC.forEach(item => {
          if (item.sxmid === 'CX') {
            this.cx = item
          }
          if (item.sxmid === 'FC') {
            this.fc = item
          }
          if (item.sxmid === 'GP') {
            this.gp = item
          }
          if (item.sxmid === 'JJ') {
            this.jj = item
          }
          if (item.sxmid === 'LC') {
            this.lc = item
          }
          if (item.sxmid === 'QT') {
            this.qt = item
          }
        })
        // 判断其他给付下子项目
        this.obcost.QTGF.forEach(item => {
          if (item.sxmid === 'FXJ') {
            this.fxj = item
          }
          if (item.sxmid === 'YZJT') {
            this.yzjt = item
          }
        })
        // 判断社保费用下子项目
        this.obcost.SBFY.forEach(item => {
          if (item.sxmid === 'GSTXJ') {
            this.gstxj = item
          }
          if (item.sxmid === 'SHYL') {
            this.shyl = item
          }
          if (item.sxmid === 'SYYL') {
            this.syyl = item
          }
        })
        // 判断医疗费用下子项目
        this.ybcost.YLFY.forEach(item => {
          if (item.sxmid === 'BFDJ') {
            this.bfdj = item
          }
          if (item.sxmid === 'HLF') {
            this.hlfy = item
          }
          if (item.sxmid === 'SRSS') {
            this.srss = item
          }
          if (item.sxmid === 'YLF') {
            this.ylfy = item
          }
        })
        this.$forceUpdate()
        if (type === true) {
          Toast.succeed('更新成功!', 1000)
        }
      })
    },
    // 编辑产品列表对应信息
    cpdataedit (val, mold) {
      if (mold == 'nbs') {
        let prod = []
        for (let item in this.proddatalist) {
          if (val.memid == this.proddatalist[item].memid) {
            prod.push(this.proddatalist[item])
          }
        }
        setStorage('proddatalist', prod)
        this.$router.push({
          path: '/productcheck',
          query: {
            mold: mold,
            // personkey: this.$route.query.personkey,
            // empuserid: this.$route.query.empuserid,
            // familyname: this.$route.query.familyname,
            // fromwhere: this.$route.query.fromwhere,
            // type: this.$route.query.type,
            // comid: this.comid,
            // familyno: this.$route.query.familyno,
            memid: val.memid,
            // cardtype: this.$route.query.cardtype,
            // cardno: this.$route.query.cardno,
            // name: this.$route.query.name,
            // empno: this.$route.query.empno
            familyname: this.$route.query.familyname,
            familyno: this.$route.query.familyno,
            comid: this.comid,
            empno: this.empno,
            fromwhere: this.$route.query.fromwhere,
            personkey: this.$route.query.personkey,
            custom: this.$route.query.custom
          }
        })
      }
    },
    jointlymode () { // 显示计划书
      this.jointlytrue = false
    },
    // 查看计划书
    makeout (val) {
      if (val.jhsno) {
        this.jointlytrue = true
        this.form = JSON.parse(val.jhsno)
      } else {
        Toast.failed('尚未生成保障方案!')
      }
    },
    compile (familyname) {
      this.$router.push({
        path: '/entering',
        query: {
          familyname: familyname,
          familyno: this.$route.query.familyno,
          comid: this.comid,
          empno: this.empno,
          fromwhere: this.$route.query.fromwhere,
          personkey: this.$route.query.personkey
        }
      })
    },
    behaviorRecord () {
      let data = {
        suserid: this.suserid,
        empno: this.empno,
        sno: this.$route.query.familyno,
        otype: '1',
        btagcode: '15',
        rytype: this.rytype
      }
      DoRecord(data).then(res => {
        let id = res.data.data.id
        let comid = this.user.comid
        this.initWebSocket(id, comid) // 开启websocket连接
      })
    },
    toShare () {
      const jsonstring = {
        url: `${redirect_uri}/nbsReport?familyname=${this.familyname}&familyno=${this.familyno}&comid=${this.comid}&empno=${this.empno}&recordtype=15&suserid=${this.user.userid}&rytype=${this.rytype}&sharetype=00`,
        title: `${this.familyname}家庭的风险分析报告`,
        description: `您的好友【${this.user.empname}】邀请您查看家庭风险分析报告`,
        logoUrl: ''
      }
      AppShare(jsonstring)
    },
    backtopage () {
      this.$router.push({ path: '/customercenter',
        query: {
          familyname: this.$route.query.familyname,
          familyno: this.$route.query.familyno,
          fromwhere: this.$route.query.fromwhere
        } })
    },
    backtohomepage () { this.$router.push({ path: '/bdtg', query: { fromwhere: this.$route.query.fromwhere, bdtgflush: true } }) }
  }
}
</script>
<style lang="stylus" scoped>
  .container {
    padding-bottom: 154px;
    width: 100%;
    overflow-x: hidden;
  }
  .content {
    width: 95%;
    margin: 0px auto;
  }
  .jt_text,.should_cos-text,.already_cos-text{
    font-size: 45px;
    font-family: "PingFang SC";
    font-weight: bold;
    padding-left: 65px;
    padding-top: 30px;
    padding-bottom: 30px;
    color: #383838;
  }
  .module-interv{
    width: 100%;
    margin-top: 80px;
  }
  .top {
    height: 340px;
    width: 100%;
    position: relative;
    // background-image: url('~@/assets/nbs/img/topbackground.png');
    // background-size: cover;
    // background-repeat: no-repeat;
    text-align: center;
  }
  .bgpr {
    background-color:color-primary;
  }
  .tx_bj {
    vertical-align: middle;
    width: 164px;
    height: 164px;
    margin-top: 50px;
  }
  .head_img{
    position: absolute;
    top: 17%;
    left: 42.5%;
    width: 150px;
    height: 150px;
  }
  .top_text{
    vertical-align: middle;
    color: #ffffff;
  }
  .btn_center{
    padding-top: 60px;
    display: flex;
    justify-content: space-around;
  }
  .btn_edit,.btn_update{
    width: 350px;
    height: 100px;
    text-align: center;
    line-height: 100px;
    color:#fff;
    display: flex;
    align-items: center;
    border-radius: 15px;
    justify-content: center;
    // background-image: url("~@/assets/nbs/img/btn_edit.png");
    // background-size: cover;
    // background-repeat: no-repeat;
  }
  .imgw img{
    width: 30px;
    height: 30px;
    margin-right: 8px;
    align-items: center;
  }
  .jt_content{
    margin-top: 50px;
  }
  /* 卡片展示信息 */
  .jt_card-center{
    display: flex;
    justify-content: space-around;
    flex-wrap:wrap;
    position: relative;
    min-height: 450px;
  }
  .jt_card{
    position: relative;
    width:300px ;
    height: 430px;
    margin-right: 10px;
    // background-image: url("~@/assets/nbs/img/jt_back-card.png");
    // background-size: cover;
    // background-repeat: no-repeat;
  }
  .ry_img{
    position: absolute;
    width: 120px;
    height: 120px;
    top: 27%;
    left: 34%;
  }
  .jt_card-relation{
    width: 120px;
    text-align: center;
    position: absolute;
    font-family: PingFang-SC-Medium;
    font-size: 30px;
    color: #FFFFFF;
    top: 12%;
    left: 35%;
  }
  .jt_card-name{
    width: 90%;
    text-align: center;
    position: absolute;
    font-weight: bolder;
    color: #383838;
    top: 60%;
    left: 10%;
  }
  .jt_card-date{
    width: 90%;
    text-align: center;
    position: absolute;
    font-family: PingFang-SC-Medium;
    font-size: 33px;
    color: #383838;
    top: 75%;
    left: 10%;
  }
  /*应备费用*/
  /* 生活/住房费用*/
  .should_cos,already_cos{
    width:100%;
    margin-bottom: 60px;
    position: relative;
  }
  .should_text-costliving,.should_text-public{
    font-size: 40px;
    font-family: PingFang-SC-Medium;
    font-weight: bold;
    padding-left: 45px;
    padding-top: 30px;
    padding-bottom: 30px;
    color: #606060;
  }
  .live {
    padding-left: 10px;
    display: flex;
    justify-content: space-around;
  }
  .live div{
    overflow: hidden;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }
  .tr_background{
    width: 100%;
    height: 100%;
    /*border: .5px solid #3196f1;*/
    background-color: color-primary;
  }
  .table_live th{
    width: 210px;
    height: 100px;
    text-align: center;
    font-size: 30px;
    color: #ffffff;
  }
   td{
    height: 100px;
    font-size:32px;
    color: #383838;
    text-align: center;
    border: .5px solid #eee;
    border-top: none;
  }
  .th_public{
    height: 100px;
    text-align: center;
    font-size: 30px;
    color: #ffffff;
  }
  .hous_width-first{
    width: 255px;
  }
  .hous_th-center{
    width: 140px;
  }
  .hous_th-last{
    width: 360px;
  }
  .table-width{
    margin-left: 30px;
    margin-right: 10px;
    margin-top: 100px;
    overflow: hidden;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }
  .table_width-title{
    width: 100%;
    padding-left: 30px;
  }
  /* 父母效养金 */
  .parant_spouse img{
    width: 40px;
    height: 40px;
    vertical-align: middle;
  }
  .parant_self img{
    width: 40px;
    height: 40px;
    vertical-align: middle;
  }
  .parant_self,.parant_spouse{
    min-width: 220px;
    background-color: color-primary;
  }
  .parant_self-value,.parant_spouse-value{
    min-width: 225px;
    height: 100px;
    color: #383838;
    text-align: center;
    border: .5px solid #eee;
    border-left: none;
  }
  .children{
    min-width: 177px;
  }
  .education{
    min-width: 179px;
  }
  .education_roll{
    width: 99%;
    padding: 25px 0;
    overflow: auto;
  }
  .family_roll{
    width: 99%;
    padding: 20px 0;
    overflow: auto;
  }
  .dkother{
    min-width: 179px;
  }
  .costother{
   min-width: 224px;
  }
  .end_cost{
    border: .5px solid color-primary;
    background-color: color-primary;
    min-width: 160px;
  }
  .end_cost-value{
    min-width: 200px;
    text-align: center;
    border: .5px solid #eee;
    border-left: none;
    color: #383838;
  }
  .jt_income{
    min-width: 224px;
  }
  .property{
    position: relative;
    min-width: 179px;
  }
  .other-public{
    border: .5px solid color-primary;
    background-color: color-primary;
    min-width: 160px;
  }
  .other_value-public{
    min-width: 200px;
    text-align: center;
    border: .5px solid #eee;
    border-left: none;
    color: #383838;
  }
  .pension{
    border: .5px solid color-primary;
    background-color: color-primary;
    min-width: 220px;
  }
  .social{
    min-width: 220px;
  }
  .data_title,.data_value{
    flex-wrap: wrap;
    display: flex;
    justify-content: space-between;
  }
  .data_title div{
    min-width: 200px;
    height: 80px;
    line-height: 80px;
    border: .5px solid #eee;
    border-radius: 30px;
    font-family: PingFang-SC-Medium;
    font-size: 38px;
    text-align: center;
    font-weight: 500;
    color: #606060;
  }
  .data_value div{
    width: 450px;
    height: 210px;
  }
  .data_value-left{
    position: relative;
    background-image: url("../../../../assets/nbs/img/data_value-left.png");
    background-size: cover;
    background-repeat: no-repeat;
  }
  .data_value-right{
    position: relative;
    background-image: url("../../../../assets/nbs/img/data_value-right.png");
    background-size: cover;
    background-repeat: no-repeat;
  }
  .data_img {
    width: 120px;
    height: 120px;
    position: absolute;
    top: 15%;
    left: 15%;
  }
  .data_value .value{
    position: absolute;
    width: 190px;
    height: auto;
    text-align: center;
    color: color-primary;
    font-weight: 400;
    font-size: 50px;
    top: 15%;
    right: 10%;
  }
  .data_value .label{
    position: absolute;
    width: 190px;
    height: auto;
    text-align: center;
    color: #6d6d6d;
    font-weight: 400;
    font-family: "PingFang SC";
    font-size: 32px;
    top: 50%;
    right: 10%;
  }
  .th_public-value {
    font-size: 32px;
    font-family: "PingFang SC";
    font-weight: 500;
    color: #383838;
  }
  .blod {
    height: 0.4rem;
    width: 0.12rem;
    background: color-primary;
    display: inline-block;
    vertical-align: -0.06rem;
    margin-right: 0.1rem;
  }
  .balan{
    min-width: 110px;
  }
  .balance{
    min-width: 219px;
  }
  .balance_radius{
    width: 93%;
    overflow: hidden;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    margin: 0px auto;
  }
  .balance_radius .balance_age{
    background-color: color-primary;
    color: #fff;
    border-left: .5px solid color-primary;
  }
  .tr_style {
    position: relative;
  }
  .th_style {
    position: absolute;
    min-width: 177px;
    height: 100px;
    background: color-primary;
    line-height: 100px;
    z-index: 9;
  }
  .td_style {
    position: absolute;
    min-width: 185px;
    height: 105px;
    background: #ffffff;
    line-height: 100px;
    z-index: 9;
    border: .5px solid #eee;
  }
  .education{
    position: relative;
  }
  .td_bordeer{
    border-left: none;
  }
  .productwrapper{
    background: #fff;
    padding: 0px 45px 60px;
  }
  .wrapper {
    background: #fff;
    padding: 45px 45px 60px;
    margin-bottom: 35px;
  }
  .caption {
    margin: 0 0 35px;
    overflow: hidden;
  }
  .caption .iconfont{
    font-size: 20px;
  }
  /* 标题 */
  .leftdiv {
    display: inline-block;
    position: relative;
    top: 4px;
    /* width: px; */
    height: 30px;
  }
  .context{
    font-size: 40px
  }
  .leftword {
    font-size: 31px;
    margin-left: 5px;
    /* font-weight: bold; */
    letter-spacing: 3px;
  }
  .rightword {
    float: right;
    font-size: 34px
  }
  .rows {
    display: flex;
  }
  .rows .lf {
    width: 33.3%;
  }
  .rows .swp {
    width: 66%;
  }
  .left-table {
    background-color: color-primary;
    color: #ffffff;
    /* font-weight: 400; */
    width: 100%;
    text-align: center;
    line-height: 70px;
  }
  .left-table tr {
    height: 70px;
    font-size: 28px;
  }
  .b-table td,
  .b-table tr,
  .b-table th {
    height: 68px;
    line-height: 68px;
    color: #646464;
    font-size: 28px;
    text-align: center;
    width: 50%;
    border: 1px solid #ddebfa;
  }
  .b-table td:nth-child(2n) {
    color: color-primary;
  }
  .b-table tr{
    height: 68px;
    font-size: 28px;
  }
  .b-table th {
    background: #eef8fb;
  }
  .b-table {
    width: 100%;
  }
  .b-table th{
    text-align: center;
  }
  .analyze thead th,
  .analyze td span {
    font-size: 18px;
  }
  .advice h3 {
    margin-bottom: 10px;
    font-size: 28px;
  }
  .scroll_parent {
    overflow: hidden;
  }
  .education_scr {
    height: 325px;
  }
  .tag_dot {
  position: absolute;
  top: 617px;
}
.navsetting{
  width 10vw
  height 30vw
  border-radius 10px
  background-color color-primary
  position fixed
  right 10px
  z-index 999
  bottom 40%
}
.navsetting1{
  width 10vw
  height 20vw
  border-radius 10px
  background-color black
  opacity 0.38
  position fixed
  right 10px
  z-index 999
  bottom 40%
}
.backbtn{
  width 80%
  margin 0 auto
  border-bottom 0.8px solid rgba(255,255,255,0.5)
  display flex
  justify-content center
  align-items center
  span{
    width 100%
    display flex
    justify-content center
    align-items center
    color white
    font-size 50px
  }
}
.backtohome{
  width 80%
  margin 0 auto
  display flex
  justify-content center
  align-items center
  span{
    display flex
    justify-content center
    align-items center
    width 100%
    color white
    font-size 50px
  }
}
.shareicon{
  width 80%
  margin 0 auto
  display flex
  justify-content center
  border-top 0.8px solid rgba(255,255,255,0.5)
  align-items center
  span{
    display flex
    justify-content center
    align-items center
    width 100%
    color white
    font-size 48px
  }
}
.navsetting2{
  width 10vw
  height 10vw
  border-radius 10px
  background-color color-primary
  position fixed
  right 10px
  z-index 999
  bottom 40%
}
.product{
border 1px solid color-primary
border-radius 15px
margin-bottom 30px
}
.producttop{
  color #fff
  height 80px
  background-color color-primary
  border-bottom 1px solid color-primary
}
.producttopname{
  padding-left 40px
  height 80px
  line-height 80px
}
.premium p{
  color #797979
  padding-left 40px
  height 80px
  line-height 80px
}
.premium span{
  color red
}
  .productcentop{
    height 40px
  }
  .productcen{
    display flex
    height 250px;
    background  #fff
    border-bottom 20px solid #eee
  }
  .productcen-lf{
    float left
    width 30%
    height 100%
    text-align center
    position relative
  }
  .productcen-lf img{
    border-radius 5px
    position absolute //相对定位
    width 180px
    height 160px
    top 0
    left 0
    right 0
    bottom 0
    margin auto //使其垂直居中
  }
  .productcen-rg{
    float left
    width 70%
    height 100%
    position relative
  }
  .productcen-rg-cen{
    position absolute //相对定位
    height 160px
    top 0
    left 0
    right 0
    bottom 0
    margin auto //使其垂直居中
  }
  .productcen-rg-top{
    height 50%
  }
  .productcen-rg-top p {
    padding-top 3%
    overflow hidden
    white-space nowrap
    text-overflow ellipsis
  }
  .productcen-rg-bom{
    display flex
  }
  .productcenp{
    margin-left 10px
  }
  .productcen-rg-bom p{
    padding-top 3%
    font-size 26px
    color #808080
  }
  .productbom{
    display flex
  }
  .productbomlff{
    width 100%
  }
  .productbomlf,.productbomrg{
    width 49.5%
  }
  .productbomrg{
    margin-left 1%
  }
  .jointlymold{
    width 100%
    height 100%
  }
</style>
